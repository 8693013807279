/* line 21, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination {
	margin-right: 10px;
	position: relative;
	margin-top: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-map-pagination {
		margin-right: 0;
	}
}

/* line 26, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .pagination {
	background: rgb(var(--color-global-widget));
	padding: 5px;
}

/* line 31, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__button,
.c-map-pagination .paging__pages,
.c-map-pagination .paging__input-holder,
.c-map-pagination .paging__separator,
.c-map-pagination .paging__numberpages {
	display: inline-block;
}

/* line 39, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__button,
.c-map-pagination .paging__input-holder {
	margin: 5px;
}

/* line 44, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__prev:after,
.c-map-pagination .paging__next:before {
	display: none;
}

/* line 49, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__prev:before,
.c-map-pagination .paging__next:after {
	content: "";
	font-size: 0;
	line-heigt: 0;
	height: 0;
	display: inline-block;
	vertical-align: middle;
	border: 5px solid transparent;
}

/* line 60, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__prev {
	border-right: 1px solid rgb(var(--color-global-text));
	padding-right: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__prev {
	border-right: 0;
	border-left: 1px solid rgb(var(--color-global-text));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__prev {
	padding-right: initial;
	padding-left: 10px;
}

/* line 64, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__prev:before {
	border-left-width: 0;
	border-right-color: rgb(var(--color-global-secondary));
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__prev:before {
	border-left-width: 0;
	border-right-width: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__prev:before {
	border-right-color: none;
	border-left-color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__prev:before {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-map-pagination .paging__prev:before {
		margin-right: 0;
	}
}

/* line 71, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__next {
	border-left: 1px solid rgb(var(--color-global-text));
	padding-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__next {
	border-left: 0;
	border-right: 1px solid rgb(var(--color-global-text));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__next {
	padding-left: initial;
	padding-right: 10px;
}

/* line 75, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__next:after {
	border-left-color: rgb(var(--color-global-secondary));
	border-right-width: 0;
	margin-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__next:after {
	border-left-color: none;
	border-right-color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__next:after {
	border-right-width: 0;
	border-left-width: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-map-pagination .paging__next:after {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-map-pagination .paging__next:after {
		margin-left: 0;
	}
}

/* line 82, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__input-holder {
	width: 40px;
}

/* line 85, scss/80-themes/Saunders/60-components/map-pagination.scss */
.c-map-pagination .paging__input-holder .o-input {
	text-align: center;
}

/*# sourceMappingURL=../../../../true */